exports.components = {
  "component---src-components-blog-blog-details-js": () => import("./../../../src/components/Blog/BlogDetails.js" /* webpackChunkName: "component---src-components-blog-blog-details-js" */),
  "component---src-components-blog-hindi-blog-details-js": () => import("./../../../src/components/Blog/HindiBlogDetails.js" /* webpackChunkName: "component---src-components-blog-hindi-blog-details-js" */),
  "component---src-components-legal-advice-details-legal-advice-details-js": () => import("./../../../src/components/LegalAdviceDetails/LegalAdviceDetails.js" /* webpackChunkName: "component---src-components-legal-advice-details-legal-advice-details-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-hindi-blog-js": () => import("./../../../src/pages/hindi-blog.js" /* webpackChunkName: "component---src-pages-hindi-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-advice-cpc-js": () => import("./../../../src/pages/legal-advice/cpc.js" /* webpackChunkName: "component---src-pages-legal-advice-cpc-js" */),
  "component---src-pages-legal-advice-crpc-js": () => import("./../../../src/pages/legal-advice/crpc.js" /* webpackChunkName: "component---src-pages-legal-advice-crpc-js" */),
  "component---src-pages-legal-advice-ipc-js": () => import("./../../../src/pages/legal-advice/ipc.js" /* webpackChunkName: "component---src-pages-legal-advice-ipc-js" */),
  "component---src-pages-legal-advice-it-act-js": () => import("./../../../src/pages/legal-advice/it-act.js" /* webpackChunkName: "component---src-pages-legal-advice-it-act-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

